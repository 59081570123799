// Dependency
import React from 'react';
import { Link } from 'gatsby';
import Img from "gatsby-image";

// Components
import Video from "../../video";

// Styles
import * as productStyles from '../../../templates/product/product.module.scss';

// Template
const ProductContent = ({ content, country }) => {

  return (
    <section className={productStyles.cdProductContentSection}>
      <div className={` ${productStyles.cdProductContentGrid} cd-max-width`}>
        {
          content && content.map((element, index) => {
            const link = country === 'fr' && element.link && element.link.includes('eu') ? element.link.replace('eu', 'fr') : element.link
            // Map data in order
            const imageUrl = element.image && element.image.fluid ? element.image.fluid : element.imageUrl;
            // Template
            return <div className={`${productStyles.cdProductContentItem} ${element.beige ? `${productStyles.cdProductContentItemBeige} cd-dark-mode-black` : ''} ${element.textleft ? productStyles.cdProductContentItemLeft : ''}`} key={index.toString()}>
              <div
                className={` ${productStyles.cdProductContentImg} cd-background-img`}
              >
                {
                  element && element.video ? <Video autoplay={'autoplay'} thumb={imageUrl} videoSrcURL={element.video.url} />
                    :
                    imageUrl && <Img fluid={imageUrl} alt={''} />
                }
              </div>
              <div className={productStyles.cdProductContent}>
                { // Mocked data
                  element.description && <div
                    className={productStyles.cdProductContentText}
                  >
                    <h5>{element.subtitle}</h5>
                    <h2>{element.title}</h2>
                    <p>{element.description}</p>
                  </div>
                }
                { // Data from DatoCMS
                  element.content && <div
                    className={productStyles.cdProductContentText}
                    dangerouslySetInnerHTML={{ __html: element.content }}
                  />
                }
                {
                  element.link ? <Link to={`/${link}/`} className="cd-link-button">{element.linkText}</Link> : ''
                }

              </div>
            </div>
          })
        }
      </div>
    </section>
  )
};

export default ProductContent;