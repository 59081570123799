// Dependency
import React, { useState } from 'react';
import Img from "gatsby-image";

// Styles
import * as styles from './box-content-pin.module.scss';

// Assets
//import imageThumbnail from '../assets/thumbnail.png';

// Template
const BoxContentPin = ({ activePin, setActivePin, pinKey, positionTop, positionLeft, pinContent, pinImage }) => {

  // Pin state
  //const [isVisible, setIsVisible] = useState(false);

  const handleActivePin = () => {
    if (activePin === pinKey) {
      setActivePin(645)
    } else {
      setActivePin(pinKey)
    }
  }

  return (
    <div
      className={
        `${styles.boxContentPin}` +
        `${positionLeft < 30 ? ` ${styles.popupLeft}` : ''}`
      }
      style={{ top: positionTop + '%', left: positionLeft + '%' }}
      onClick={() => handleActivePin()}
    >
      <div className={`${styles.boxContentPin__tooltip} ${activePin === pinKey ? styles.isVisible : ''}`}>
        <div className={`${styles.boxContentPin__img}`}>
          {pinImage ? <Img fluid={pinImage} alt={""} /> : ""}

        </div>
        <div dangerouslySetInnerHTML={{ __html: pinContent }}>
        </div>
        <i className="material-icons">close</i>
      </div>
    </div>
  );
};

export default BoxContentPin;