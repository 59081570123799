// Service for handling all vaucers related stuff

// Dependency
import AjaxService from "./ajax.service";

// Service
const VoucersService = {

  // Submit vaucer
  submit: (vaucerCode, token) => {
    return AjaxService.post(`${process.env.CENTRA_URL_VOUCHERS}`, vaucerCode, token);
  },

  remove: (voucherCode, token) => {
    return AjaxService.delete(`${process.env.CENTRA_URL_VOUCHERS}/${voucherCode}`, token);
  }

};

export default VoucersService;