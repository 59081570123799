// AJAX service for handling server communication
import axios from 'axios';

// Settings - cant show secret key on requests
//axios.defaults.headers.put['Authorization'] = `Bearer bFCykqiiPmkELTEmXdbXXkKGbUpFvgUn`;

// Service
const AjaxService = {

  // POST request
  post: (requestUrl, bodyData, token) => {
    axios.defaults.headers.post['Accept'] = `*/*; api-token: ${token}`;
    // Send request
    return axios.post(requestUrl, bodyData);
  },

  delete: (requestUrl, token) => {
    axios.defaults.headers.delete['Accept'] = `*/*; api-token: ${token}`;
    // Send request
    return axios.delete(requestUrl);
  }
};

export default AjaxService;