// Dependency
import React from 'react';
import Img from "gatsby-image";

// Components
import Video from "../../video";

// Styles
import * as galleryStyles from './product-gallery.module.scss';

// Template
const ProductGallery= ({ images }) => {
  return (
    <div className={galleryStyles.productGallery}>
       { images && images.map( (element, index) => {
          return (
            <div key={index} className={`${galleryStyles.productGalleryImgWrapper}`}>
              <div className={`${galleryStyles.productGalleryImg} cd-background-img`}>
                  {element.isImage === false ?
                      <Video autoplay={'autoplay'} videoSrcURL={element.url} />
                      :
                      <Img fluid={element.fluid} alt={''} />
                  }
              </div>
            </div>
          )
        })}
    </div>
  )
};

export default ProductGallery;