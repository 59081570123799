import React, { useEffect } from "react"
//import useTestFreak from "../../hooks/useTestFreak"

const TestFreak = props => {
  const componentProps = props.blok ? props.blok : props.props
  //useTestFreak({ type: componentProps.type, productId: componentProps.productId, country: componentProps.country })

  useEffect(() => {
    const loadTestFreaks = callback => {
      const existingScript = document.getElementById("tF")
      if (!existingScript) {
        const script = document.createElement("script")
        script.src = `https://js.testfreaks.com/onpage/flowlife.com-${componentProps.country}/head.js`
        script.id = "tF"
        document.head.appendChild(script)
        script.onload = () => {
          if (callback) callback()
        }
      }
      if (existingScript && callback) callback()
    }

    loadTestFreaks(() => {
      let testFreaks = window.testFreaks || []
      if (componentProps.type === 'items') {
        testFreaks.push(["load", "items"])
      } else {
        testFreaks.push(["setProductId", componentProps.productId])
        testFreaks.push(["load", ["badge", "reviews"]])
        testFreaks.push(["setAnalytics", "dataLayer"])
        testFreaks.push(["setAggregateRatingSchema", "json-ld"])
      }

    })
    return () => {
      delete window.testFreaks
      document.getElementById("tF")?.remove()
    }
  }, [componentProps.productId])


  return (
    <>
      {componentProps && (
        <div id={`${componentProps.type}-${componentProps.productId}`}>
          {componentProps.type === 'reviews' ? <div id="testfreaks-reviews" /> : componentProps.type === 'items' ? <div class="testfreaks-item" data-product-id={componentProps.productId}></div> : <div id="testfreaks-badge"></div>}
        </div>
      )}
    </>
  )
}
export default TestFreak