// Dependency
import React from "react";
import { useTranslation } from 'react-i18next';

// Context
import ContentFlyinContext from '../../modals/modal-flyin/ContentFlyinContext';

// Styles
import * as productStyles from '../../../templates/product/product.module.scss';

// Template
const ProductPearksModal = ({ product }) => {

  // Translation Function
  const { t } = useTranslation();

  // Open Modal and set it data
  const passDataToModal = (context, content) => {
    context.toggleContentModal();
    context.setContentModalContent(content);
  }

  return (
    <ContentFlyinContext.Consumer>
      {(contentModalOpen) => <div className={productStyles.cdSingleProductContentPerks}>
        { // visible only if data exist
          product && product.bestInTest && <div className={productStyles.cdSingleProductContentPerk}>
            <i className="material-icons">check</i>
            <p>{t('productInfo.bestTest')}</p>
            {/*Toggle modal with bestInTest content*/}
            <button className="cd-link-button" onClick={() => { passDataToModal(contentModalOpen, product.bestInTest) }}>{t('productInfo.readMore')}</button>
          </div>
        }
        {
          product.modalPerks && product.modalPerks.map((element) => {
            return <div className={productStyles.cdSingleProductContentPerk} key={element.title}>
              <i className="material-icons">check</i>
              {
                element.contenttitle ? <div dangerouslySetInnerHTML={{ __html: element.contenttitle }}></div> : <p>{element.title}</p>

              }
              
              {element.content ? <button className="cd-link-button" onClick={() => { passDataToModal(contentModalOpen, element.content) }}>{t('productInfo.readMore')}</button>
                :
                ''
              }
            </div>
          })
        }
      </div>
      }
    </ContentFlyinContext.Consumer>
  )
};

export default ProductPearksModal;