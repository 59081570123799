// extracted by mini-css-extract-plugin
export var cdCollabBackgroundCards = "compare-module--cd-collab-background-cards--1e351";
export var cdCompareCol = "compare-module--cd-compare-col--00798";
export var cdCompareColContent = "compare-module--cd-compare-col-content--0247f";
export var cdCompareFilter = "compare-module--cd-compare-filter--16f69";
export var cdCompareGrid = "compare-module--cd-compare-grid--8c71c";
export var cdComparePattern = "compare-module--cd-compare-pattern--a83b1";
export var cdComparePatternImg = "compare-module--cd-compare-pattern-img--f372d";
export var cdCompareProductImg = "compare-module--cd-compare-product-img--d61bd";
export var cdCompareRow = "compare-module--cd-compare-row--ad4ea";
export var cdCompareRowDivider = "compare-module--cd-compare-row-divider--99753";
export var cdCompareRowWrapper = "compare-module--cd-compare-row-wrapper--f5b68";
export var cdCompareSection = "compare-module--cd-compare-section--2fa2e";
export var cdCompareTab = "compare-module--cd-compare-tab--571cc";
export var cdCompareVideo = "compare-module--cd-compare-video--2d705";
export var cdFilterButton = "compare-module--cd-filter-button--d9404";
export var cdProductPrice = "compare-module--cd-product-price--3ed3e";
export var cdVideoPlayer = "compare-module--cd-video-player--3e1a4";