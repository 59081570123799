// Dependency
import React from 'react';
import useMediaQuery from '../../../hooks/useMediaQuery';

// Components
import Tabs from "../../tabs/tabs";
import TrustBox from "../../trustpilot/trustbox";
import BoxContent from "../../box-content/box-content";
import Stories from "../../stories";
import Accordion from "../../faq/accordion";
import TestFreak from "../../testfreak/testfreak";

// Internationalization
import { useTranslation } from 'react-i18next';

// Styles
import * as productStyles from '../../../templates/product/product.module.scss';
import * as compareStyles from '../../../templates/pages/compare.module.scss'

// Template
const ProductTabs = (props) => {

  // Translation Function
  const { t } = useTranslation();

  const toggleTab = (tabTop, tabBottom) => {
    //Transform animation
    tabBottom.offsetParent === null ? tabTop.classList.add(productStyles.cdProductTabsOpened) : tabTop.classList.remove(productStyles.cdProductTabsOpened);
    //Toggle visibillity
    tabBottom.style = tabBottom.offsetParent === null ? 'display: block; max-height: 2000px;' : 'display: none; max-height: 0;';
  }

  const isTabletOrMobile = useMediaQuery(900);

  console.log(props)

  return (
   
      <section className="cd-single-product-tabs">
            <>
              <div className={productStyles.cdSingleProductTabs}>
                <div className={productStyles.cdSingleProductTabTop} onClick={(e) => {
                  toggleTab(e.currentTarget, e.currentTarget.nextElementSibling)
                }}>
                  <span>{t('productTabs.desc')}</span>
                  <i className="material-icons">add</i>
                </div>
                <div className={productStyles.cdProductTabContent}>
                  <div className={productStyles.cdExperienceSection}>
                    <div className={productStyles.cdExperienceGrid}>
                      <div className={productStyles.cdExperienceLeft} dangerouslySetInnerHTML={{__html: props.desc}}></div>
                    </div>
                  </div>
                </div>
              </div>

              {props.bulletPoints ?
                    <div className={productStyles.cdSingleProductTabs}>
                      <div className={productStyles.cdSingleProductTabTop} onClick={(e) => {
                        toggleTab(e.currentTarget, e.currentTarget.nextElementSibling)
                      }}>
                        <span>{t('productTabs.info')}</span>
                        <i className="material-icons">add</i>
                      </div>
                      <div className={productStyles.cdProductTabContent}>
                        <div className={productStyles.cdExperienceSection}>
                          <div className={productStyles.cdExperienceGrid}>
                            <div className={productStyles.cdExperienceLeft} dangerouslySetInnerHTML={{__html: props.bulletPoints}}>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div className={productStyles.cdSingleProductTabs}>
                      <div className={productStyles.cdSingleProductTabTop} onClick={(e) => {
                        toggleTab(e.currentTarget, e.currentTarget.nextElementSibling)
                      }}>
                        <span>{t('productTabs.info')}</span>
                        <i className="material-icons">add</i>
                      </div>
                      <div className={productStyles.cdProductTabContent}>
                        <div className={productStyles.cdExperienceSection}>
                            <div className={`${productStyles.cdSpecGrid} ${compareStyles.cdCompareGrid}`}>
                              { //visible only if data exist
                                props && props.batteryTime &&
                                <div className={productStyles.cdSpecCol}>
                                  <div className={compareStyles.cdCompareTab}>
                                    <h4>{t('compare.battery')}</h4>
                                  </div>
                                  { //visible only if data exist
                                    props && props.batteryText && <div className={compareStyles.cdCompareRowWrapper}>
                                      <div className={compareStyles.cdCompareRow}>
                                        <div className={`${productStyles.cdSpecItem} ${compareStyles.cdCompareCol}`}>
                                          <div className={compareStyles.cdCompareColContent}>
                                            <p>{props.batteryText}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  <div className={compareStyles.cdCompareRowWrapper}>
                                    <h5>{t('compare.batteryLife')}</h5>
                                    <div className={compareStyles.cdCompareRow}>
                                      <div className={`${productStyles.cdSpecItem} ${compareStyles.cdCompareCol}`}>
                                        <div className={compareStyles.cdCompareColContent}>
                                          <p>{props.batteryTime}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  { //visible only if data exist
                                    props && props.loadTime && <div className={compareStyles.cdCompareRowWrapper}>
                                      <h5>{t('compare.loadTime')}</h5>
                                      <div className={compareStyles.cdCompareRow}>
                                        <div className={`${productStyles.cdSpecItem} ${compareStyles.cdCompareCol}`}>
                                          <div className={compareStyles.cdCompareColContent}>
                                            <p>{props.loadTime}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  { //visible only if data exist
                                    props && props.batteryType && <div className={compareStyles.cdCompareRowWrapper}>
                                      <h5>{t('compare.batteryType')}</h5>
                                      <div className={compareStyles.cdCompareRow}>
                                        <div className={`${productStyles.cdSpecItem} ${compareStyles.cdCompareCol}`}>
                                          <div className={compareStyles.cdCompareColContent}>
                                            <p>{props.batteryType}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                </div>
                              }

                              { //visible only if data exist
                                props && props.strokeLength && <div className={productStyles.cdSpecCol}>
                                  <div className={compareStyles.cdCompareTab}>
                                    <h4>{t('compare.performance')}</h4>
                                  </div>

                                  { //visible only if data exist
                                    props && props.performance && <div className={compareStyles.cdCompareRowWrapper}>
                                      <div className={compareStyles.cdCompareRow}>
                                        <div className={`${productStyles.cdSpecItem} ${compareStyles.cdCompareCol}`}>
                                          <div className={compareStyles.cdCompareColContent}>
                                            <p>{props.performance}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  <div className={compareStyles.cdCompareRowWrapper}>
                                    <h5>{t('compare.stroke')}</h5>
                                    <div className={compareStyles.cdCompareRow}>
                                      <div className={`${productStyles.cdSpecItem} ${compareStyles.cdCompareCol}`}>
                                        <div className={compareStyles.cdCompareColContent}>
                                          <p>{props.strokeLength}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  { //visible only if data exist
                                    props && props.beatsPerMinute && <div className={compareStyles.cdCompareRowWrapper}>
                                      <h5>{t('compare.beats')}</h5>
                                      <div className={compareStyles.cdCompareRow}>
                                        <div className={`${productStyles.cdSpecItem} ${compareStyles.cdCompareCol}`}>
                                          <div className={compareStyles.cdCompareColContent}>
                                            <p>{props.beatsPerMinute}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                </div>
                              }

                              { //visible only if data exist
                                props && props.noiseLevel &&

                                <div className={productStyles.cdSpecCol}>
                                  <div className={compareStyles.cdCompareTab}>
                                    <h4>{t('compare.motor')}</h4>
                                  </div>

                                  { //visible only if data exist
                                    props && props.motor && <div className={compareStyles.cdCompareRowWrapper}>
                                      <div className={compareStyles.cdCompareRow}>
                                        <div className={`${productStyles.cdSpecItem} ${compareStyles.cdCompareCol}`}>
                                          <div className={compareStyles.cdCompareColContent}>
                                            <p>{props.motor}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  <div className={compareStyles.cdCompareRowWrapper}>
                                    <h5>{t('compare.noise')}</h5>
                                    <div className={compareStyles.cdCompareRow}>
                                      <div className={`${productStyles.cdSpecItem} ${compareStyles.cdCompareCol}`}>
                                        <div className={compareStyles.cdCompareColContent}>
                                          <p>{props.noiseLevel}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  { //visible only if data exist
                                    props && props.maximumPressure && <div className={compareStyles.cdCompareRowWrapper}>
                                      <h5>Maximum pressure</h5>
                                      <div className={compareStyles.cdCompareRow}>
                                        <div className={`${productStyles.cdSpecItem} ${compareStyles.cdCompareCol}`}>
                                          <div className={compareStyles.cdCompareColContent}>
                                            <p>{props.maximumPressure}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                </div>
                              }

                              { //visible only if data exist
                                props && <div className={productStyles.cdSpecCol}>
                                  <div className={compareStyles.cdCompareTab}>
                                    <h4>{t('compare.spec')}</h4>
                                  </div>

                                  { //visible only if data exist
                                    props && props.specifications && <div className={compareStyles.cdCompareRowWrapper}>
                                      <div className={compareStyles.cdCompareRow}>
                                        <div className={`${productStyles.cdSpecItem} ${compareStyles.cdCompareCol}`}>
                                          <div className={compareStyles.cdCompareColContent}>
                                            <p>{props.specifications}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  { //visible only if data exist
                                    props && props.weight &&
                                    <div className={compareStyles.cdCompareRowWrapper}>
                                      <h5>{t('compare.weight')}</h5>
                                      <div className={compareStyles.cdCompareRow}>
                                        <div className={`${productStyles.cdSpecItem} ${compareStyles.cdCompareCol}`}>
                                          <div className={compareStyles.cdCompareColContent}>
                                            <p>{props.weight}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  { //visible only if data exist
                                    props && props.size && <div className={compareStyles.cdCompareRowWrapper}>
                                      <h5>{t('compare.size')}</h5>
                                      <div className={compareStyles.cdCompareRow}>
                                        <div className={`${productStyles.cdSpecItem} ${compareStyles.cdCompareCol}`}>
                                          <div className={compareStyles.cdCompareColContent}>
                                            <p>{props.size}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }

                                  { //visible only if data exist
                                    props && props.dimensions && <div className={compareStyles.cdCompareRowWrapper}>
                                      <h5>{t('compare.dimensions')}</h5>
                                      <div className={compareStyles.cdCompareRow}>
                                        <div className={`${productStyles.cdSpecItem} ${compareStyles.cdCompareCol}`}>
                                          <div className={compareStyles.cdCompareColContent}>
                                            <p>{props.dimensions}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                </div>
                              }
                            </div>
                        </div>
                      </div>
                    </div>
              }
              {
                (props && props.imgBoxContent || props.boxContent)  &&        
                <div className={productStyles.cdSingleProductTabs}>
                  <div className={productStyles.cdSingleProductTabTop} onClick={(e) => {
                    toggleTab(e.currentTarget, e.currentTarget.nextElementSibling)
                  }}>
                    <span>{t('productTabs.box')}</span>
                    <i className="material-icons">add</i>
                  </div>
                  <div className={productStyles.cdProductTabContent}>
                    <BoxContent
                        pins={props.pins}
                        boxContent={props.boxContent}
                        imgBoxContent={props.imgBoxContent}
                    />
                  </div>
                </div>
              }
            {props.productReview ?
                  <div className={productStyles.cdSingleProductTabs}>
                    <div className={productStyles.cdSingleProductTabTop} onClick={(e) => {
                      toggleTab(e.currentTarget, e.currentTarget.nextElementSibling)
                    }}>
                      <span>{t('reviews.reviews')}</span>
                      <i className="material-icons">add</i>
                    </div>
                    <div className={`${productStyles.cdProductTabContent} ${productStyles.cdProductTabContentReview}`}>
                      <div className={productStyles.cdExperienceSection}>
                        {/* <ProductReview
                            title={props.title}
                            subtitle={t('reviews.users')}
                            templateid={'5763bccae0a06d08e809ecbb'}
                            linktext={t('reviews.seeReviews')}
                            SKU={props.productReview}
                            locale={props.locale}
                        /> */}
                          <TestFreak
                              props={{
                                reviews: false,
                                type: "reviews",
                                productId: props.productReview,
                                country: props.country
                              }}
                            />           
                      </div>
                    </div>
                  </div>
                  :
                  ''
              }

            </>
            </section>
  )
};

export default ProductTabs;