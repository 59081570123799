// Dependency
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Img from "gatsby-image";

// Components
import Video from "../../video";

// Styles
import * as productStyles from '../../../templates/product/product.module.scss';

// Template
const ProductSlider = ({ images, video }) => {
    return (
        <Swiper className={productStyles.cdSingleProductGallery}
                spaceBetween={0}
                slidesPerView={1}
                slidesPerGroup={1}
                navigation={ {
                    nextEl: '.cd-gallery-next',
                    prevEl: '.cd-gallery-prev'
                }}
                pagination={{
                    clickable: true
                }}
                grabCursor={true}
                loop={true}
        >
            { images && images.map( (element, index) => {
                return <SwiperSlide key={index.toString()}>
                    <span className={`${productStyles.cdSingleProductGalleryImg} cd-background-img`}>
                        {element.isImage === false ?
                            <Video autoplay={'autoplay'} videoSrcURL={element.url} />
                            :
                            <Img fluid={element.fluid} alt={''} />
                        }
                    </span>
                </SwiperSlide>

            }) }

            <div className={productStyles.cdSingleProductGalleryArrows}>
                <div className={` ${productStyles.cdGalleryNext} cd-swiper-arrows cd-next-arrow cd-gallery-next`} ><div className="cd-arrow"></div></div>
                <div className={` ${productStyles.cdGalleryPrev} cd-swiper-arrows cd-prev-arrow cd-gallery-prev`} ><div className="cd-arrow"></div></div>
            </div>
        </Swiper>
    )
};

export default ProductSlider;