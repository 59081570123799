// Dependency
import React, { useEffect } from "react";
import axios from "axios";

// Components
import { useCart } from "../../../contexts/cart-context";
import VoucersService from "../../../services/vouchers.service";

// Template
export default function ProductData ({ country, items, setProductData, voucher }) {

    const { cart } = useCart();

    useEffect( ()=>{
      const products = [];
      const getProds = async (items) => {
        //let data;
        for (const [i, item] of items.entries()) {
            await axios({
            method: 'get',
            url: `${process.env.CENTRA_URL_PRODUCTS}/${item.id}`
          }).then(response => {
            //console.log(response.data);
            products.push(response.data.product);
            //data = response.data.product;
            //setProductData(response.data.product);
            if (i === (items.length-1)) setProductData(products)
          }).catch((error) => {
            console.log(error)
          });
            if ( voucher ) {
                VoucersService.submit({voucher: voucher}, cart.token).then((response) => {
                    //console.log(response.data);
                }).catch((response) => {
                    //console.log(response)
                });
            }
        }
        //console.log('Data',data);
        //return data;
      }
      const realCountry = country === 'eu' ? 'en' : country
      if ( cart.token && realCountry === cart.selection.language ){
        axios.defaults.headers.get['Accept'] = `*/*;api-token: ${cart.token}`;
        getProds(items);
      }
    }, [cart, country, items])

    return (
        <>
        </>
    )
}
