// Dependency
import React from "react";
import Img from "gatsby-image";
import ReactPlayer from 'react-player'

// Components
import Video from "./video";

// Styles
import * as mediaStyles from './media-banner.module.scss'

// Hooks
import useMediaQuery from '../hooks/useMediaQuery';

// Template
const MediaBanner = ({videoSrcURLMobile, videoSrcURL, desktopImage, mobileImage, sources, children, audibleVideo, audibleVideoMobile, iframe }) => {

    const isTabletOrMobile = useMediaQuery(900)

    return (
        <section className={`${mediaStyles.cdMediaBanner} cd-background-img`}>
            {
              !videoSrcURL && sources && <Img fluid={sources} alt={''} />
            }

            {!isTabletOrMobile ?

                iframe ?
                    <ReactPlayer
                        url={iframe.url}
                        width="100%"
                        height="100%"
                        loop={true}
                        controls={true}
                        className='cd-react-player cd-background-img'
                        playing
                        playIcon={<div className="cd-play-icon"><i className="material-icons">play_arrow</i></div>}
                        light={desktopImage.url}
                    />
                :
                audibleVideo ?
                    <ReactPlayer
                        url={audibleVideo.url}
                        width="100%"
                        height="100%"
                        loop={true}
                        controls={true}
                        className='cd-react-player cd-background-img'
                        playing
                        playIcon={<div className="cd-play-icon"><i className="material-icons">play_arrow</i></div>}
                        light={desktopImage.url}
                    />
                :
                    videoSrcURL && videoSrcURL.url && <div>
                        <Video autoplay={'autoplay'} format={videoSrcURL.format} thumb={`${desktopImage ? desktopImage.fluid : '' }`} videoSrcURL={videoSrcURL.url} />
                    </div>
            :
                iframe ?
                    <ReactPlayer
                        url={iframe.url}
                        width="100%"
                        height="100%"
                        loop={true}
                        controls={true}
                        className='cd-react-player cd-background-img'
                        playing
                        playIcon={<div className="cd-play-icon"><i className="material-icons">play_arrow</i></div>}
                        light={desktopImage.url}
                    />
                :
                audibleVideoMobile ?
                    <ReactPlayer
                        url={audibleVideoMobile.url}
                        width="100%"
                        height="100%"
                        loop={true}
                        controls={true}
                        className='cd-react-player cd-background-img'
                        playing
                        playIcon={<div className="cd-play-icon"><i className="material-icons">play_arrow</i></div>}
                        light={mobileImage.url}
                    />
                :
                    videoSrcURLMobile && videoSrcURLMobile.url && <div>
                        <Video autoplay={'autoplay'} format={videoSrcURLMobile.format} thumb={`${mobileImage ? mobileImage.fluid : '' }`} videoSrcURL={videoSrcURLMobile.url} />
                    </div>
            }
            {
                children.props.dangerouslySetInnerHTML.__html ?
                    <div className={mediaStyles.cdMediaBannerContent}>
                        <div className="cd-max-width">
                            {children}
                        </div>
                    </div>
                    :
                    ''
            }

        </section>
    )
}

export default MediaBanner
