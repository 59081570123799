// Dependency
import React from "react";
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Components
import ProductCard from "../components/cards/product-card";

// Styles
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);


// Template
export default function ProductSwiper ({ productList, data, limit, children, placement }) {
    //console.log('ProductSwiper data: ', data);
    //console.log('Children: ', children);

    const list = placement ? placement : 'Shop';

    return (

        <div className="cd-swiper-wrapper">
            <Swiper className={`cd-swiper cd-product-swiper`}
                    spaceBetween={10}
                    slidesPerView={1}
                    grabCursor={true}
                    watchSlidesProgress={true}
                    navigation={ {
                        nextEl: '.cd-product-next',
                        prevEl: '.cd-product-prev'
                    }}
                    breakpoints={{
                        1100: {
                            spaceBetween: 16,
                            slidesPerView: 4,
                            slidesPerGroup: 1
                        },
    
                        600: {
                            spaceBetween: 10,
                            slidesPerView: 2,
                            slidesPerGroup: 1
                        },
                    }}
            >
                {
                    data && data.map( (element, index) => {
                        //Get mapped Centra Prod
                        let prodFilter = productList.filter((prod) => {
                            return element.centraId.toString() === prod.centraProduct;
                        })

                        let centraProd = prodFilter.length > 0 ? prodFilter[0] : [];

                        if (limit) {
                            if ( index < limit) {
                              return <SwiperSlide key={index} ><ProductCard centraProd={centraProd} data={element} placement={list} position={index+1} /></SwiperSlide>
                            }
                        } else {
                            return <SwiperSlide key={index} ><ProductCard centraProd={centraProd} data={element} placement={list} position={index+1} /></SwiperSlide>
                        }
                    })
                }
                { children && <SwiperSlide key={8768} >{children}</SwiperSlide> }
                <div className={` cd-swiper-arrows cd-next-arrow cd-product-next`} ><div className="cd-arrow"></div></div>
                <div className={` cd-swiper-arrows cd-prev-arrow cd-product-prev`} ><div className="cd-arrow"></div></div>
            </Swiper>
        </div>
    )
}
