// Dependency
import React from 'react';
import Img from "gatsby-image";
import ContentFlyinContext from '../../modals/modal-flyin/ContentFlyinContext';
import BundleModal from '../bundle/bundle-modal';

// Components

// Styles
import * as styles from './bundle-products.module.scss';

// Internationalization
import { useTranslation } from 'react-i18next';

// Template
const BundleProducts = ( props ) => {

    // Translation Function
    const { t } = useTranslation();

    const passDataToModal = (context, content) => {
        context.toggleContentModal();
        context.setContentModalContent(content);
    }

    const mapCentraProd = (product) => {
        const centraP = props.centraProducts.filter( prod => prod.centraProduct === `${product.centraId}` );
        const combinedProdData = {
            centraProduct: centraP[0],
            datoProduct: product,
            locale: props.locale
        }
        return combinedProdData;
    }

    return (
        <ContentFlyinContext.Consumer>
            {(contentModalOpen) => 
                <div className={styles.cdBundleProductsContainer}>
                    <div className={styles.cdBundleProducts}>
                        { props.data && props.data.map( (product, index) => {
                            const prodData = mapCentraProd(product);
                            //console.log(prodData);
                            return (
                                <div key={index} className={styles.cdBundleProductItem} onClick={() => {passDataToModal(contentModalOpen, prodData)}} >
                                    <div className={`${styles.cdBundleProductImg} cd-background-img`}>
                                        <div className={styles.cdBundleProductImgWrapper}>
                                            <Img fluid={product.featuredImage.fluid} />
                                        </div>
                                    </div>
                                    <div className={styles.cdBundleProductContent}>
                                        <h5>{t('productInfo.bundleInclude')}</h5>
                                        <p>{prodData.centraProduct && prodData.centraProduct.variantName ? prodData.centraProduct.variantName : product.title}</p>
                                        <span>{t('productInfo.readMore')}</span>
                                    </div>
                                </div>
                            )
                        }) }
                    </div>
                </div>
            }
        </ContentFlyinContext.Consumer>
    )
};

export default BundleProducts;