// Dependency
import React, { useState } from 'react';

// Components
import BoxContentPin from './box-content-pin/box-content-pin';

// Styles
import * as styles from './box-content.module.scss';

// Assets

// Template
const BoxContent = (props) => {

  const [activePin, setActivePin] = useState(false)

  return (
    <div className={`${styles.boxContent}`}>
      <div className={`${styles.boxContent__wrapper}`}>
        <div className={`${styles.boxContent__text}`} dangerouslySetInnerHTML={{ __html: props.boxContent }}>
        </div>
        <div className={`${styles.boxContent__content}`}>
          <img src={props.imgBoxContent} alt={""} />
          {props && props.pins && props.pins.map((pin, index) => {
            return (
              <BoxContentPin
                key={index.toString()}
                pinContent={pin.content}
                pinImage={pin.image.fluid}
                positionTop={pin.positionTop}
                positionLeft={pin.positionLeft}
                activePin={activePin}
                setActivePin={setActivePin}
                pinKey={index}
              />
            )
          })}
        </div>
      </div>
    </div>
  );
};

export default BoxContent;