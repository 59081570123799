// Dependency
import React from 'react';

// Styles
import * as productStyles from '../../../templates/product/product.module.scss';

// Template
const ProductPerksImage = ({ imagePerks }) => {
  return (
    <div className={productStyles.cdSingleProductImgPerks}>
      {
        imagePerks && imagePerks.map( (element, index) => {
          return <div
            className={productStyles.cdSingleProductImgPerk}
            key={index.toString()}
          >
            <i className="material-icons">check</i>
            <p>{element.text}</p>
        </div>
        })
      }
    </div>
  )
};

export default ProductPerksImage;